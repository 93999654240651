<template>
  <div class="creatCenterItem">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <!-- <div class="top" style="width: 100%;height: 1px"></div> -->
      <PostItem
        :isCreateCenter="true"
        v-for="(item, index) in list"
        :key="index"
        :postInfo="item"
        @delPost="delPost"
      />
    </PullRefresh>
  </div>
</template>

<script>
import PullRefresh from "@/components/PullRefresh";
import PostItem from "@/components/PostItem";
import { queryCollection, delVid } from "@/api/mine";
import { Toast, Dialog } from "vant";
export default {
  components: {
    PostItem,
    PullRefresh,
  },
  props: {
    status: {
      type: Number,
      required: true,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList("");
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        status: this.status,
      };
      try {
        let res = await this.$Api(queryCollection, req);
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    delPost(postInfo) {
      this.$emit("delPost", postInfo);
      Dialog.confirm({
        // title: '标题',
        message: "是否确认删除该帖子",
      })
        .then(() => {
          this.comfirmDel(postInfo);
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    async comfirmDel(postInfo) {
      let req = {
        ids: [postInfo.id],
      };
      try {
        this.$store.commit("app/SET_LOADING", true);
        let res = await this.$Api(delVid, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res && res.code == 200) {
          this.list.forEach((i, j) => {
            if (i.id == postInfo.id) {
              this.list.splice(j, 1);
            }
          });
          Toast("删除成功");
        } else {
          Toast(res.tip || "操作失败");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.creatCenterItem {
  height: calc(100vh - 180px);
}
</style>
