<template>
  <div class="creationCenter">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">创作中心</div>
      <!-- <div class="rightBtn"> -->
      <van-popover
        class="rightBtn"
        v-model="showPopover"
        trigger="click"
        placement="bottom-end"
      >
        <div class="releaseBtns" @click.stop>
          <div class="releaseBtnGroup">
            <div class="item" @click.stop="jumpImg">
              <img src="@/assets/png/imgIcon.png" />
              <span>图片</span>
            </div>
            <div class="division"></div>
            <div class="item" @click.stop="jumpVideo">
              <img src="@/assets/png/videoIcon.png" />
              <span>视频</span>
            </div>
          </div>
        </div>
        <template #reference v-if="this.active === 0 || this.active === 1">
          <div class="publish">发布</div>
        </template>
      </van-popover>
      <!-- </div> -->
      <div v-if="this.active === 2">
        <div @click="callDelPost">清空</div>
      </div>
    </div>
    <div class="main">
      <van-tabs
        class="creatTab"
        v-model="active"
        title-active-color="#555"
        title-inactive-color="#999"
        background="rgba(0,0,0,0)"
      >
        <van-tab title="已发布">
          <creationCenterItem :status="1" />
        </van-tab>
        <van-tab title="待审核">
          <creationCenterItem :status="0" />
        </van-tab>
        <van-tab title="未通过">
          <creationCenterItem ref="del" :status="2" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Toast } from "vant";
import creationCenterItem from "./creationCenterItem";
import { mapGetters } from "vuex";

export default {
  name: "creationCenter",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    creationCenterItem,
  },
  data() {
    return {
      active: 0,
      showPopover: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      isVip: "isVip",
    }),
  },
  methods: {
    // 删除
    callDelPost() {
      // this.$refs.del.callDelPost();
      this.$bus.$emit("callDelPost");
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    jumpImg() {
      this.$router.push("/publishImg");
      //  if (!this.userInfo.isVip) {
      //   this.$router.push("/publishImg")
      //  } else {
      //   this.$bus.$emit("vipPopup", {
      //    // id: "",
      //    state: 1,
      //    // goldenNum: this.postInfo.coins,
      //    closeBtn: () => {
      //     this.$bus.$emit("closeVipPopup");
      //    }
      //   });
      //   this.showPopover = false;
      //  }
    },
    jumpVideo() {
      this.$router.push("/publishVideo");
      //  if (!this.userInfo.isVip) {
      //   this.$router.push("/publishVideo")
      //  } else {
      //   this.$bus.$emit("vipPopup", {
      //    // id: "",
      //    state: 1,
      //    // goldenNum: this.postInfo.coins,
      //    closeBtn: () => {
      //     this.$bus.$emit("closeVipPopup");
      //    }
      //   });
      //   this.showPopover = false;
      //  }
    },
  },
};
</script>

<style lang="scss" scoped>
.releaseBtns {
  display: flex;
  justify-content: flex-end;

  .releaseBtnGroup {
    //width: 121px;
    //height: 41px;
    background: linear-gradient(180deg, #e6feff 0%, #fff 100%);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 10px 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    font-size: 15px;
    color: rgb(255, 255, 255);

    .item {
      display: flex;
      align-items: center;
      color: #94d6da;
      font-weight: bold;
      font-size: 14px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .division {
      width: 84px;
      height: 1px;
      background: #94d6da;
      margin: 6px 0;
    }
  }
}

.popoverContent {
  width: 121px;
  height: 41px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #94d6da;
  color: #fff;

  .line {
    width: 1px;
    height: 33px;
    background: #fff;
  }

  .popoverItem {
    width: 60px;
    text-align: center;
  }
}

.creationCenter {
  height: 100%;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    //border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #333333;
    }

    .rightBtn {
      .publish {
        font-size: 12px;
        color: #333333;
        // width: 18px;
        // height: 19px;
      }
    }
  }

  .main {
    height: 100%;
    // padding: 0 12px;
    box-sizing: border-box;
  }

  .creatTab {
    /deep/ .van-tabs__wrap {
      width: 342px;
      height: 33px;
      margin: 20px auto 0;
      //margin-bottom: 12px;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.05);
      background: #f0f0f3;
      padding: 3.5px 30.03px 3.5px 28.782px;
      box-sizing: border-box;
    }

    /deep/ .van-tabs__nav {
      display: flex;
      justify-content: center;
    }

    /deep/ .van-tab {
      // width: 54px;
      flex-shrink: 0;
      width: 104.776px;
      height: 26px;
    }

    /deep/ .van-tab__text {
      font-size: 12px;
      font-weight: 400;
      font-weight: 900;
    }

    /deep/ .van-tab--active {
      font-weight: 900;
      width: 104.776px;
      height: 26px;
      // border-radius: 8px;
      // border: 2px solid rgba(0, 0, 0, 0.05);
      // background: #f0f0f3;
      background: url("../../../assets/png/tabBg.png");
      background-size: 100% 100%;
      // color: #555;
    }

    /deep/ .van-tabs__line {
      width: 18px;
      height: 2px;
      background: #94d6da;
      bottom: 22px;
      display: none;
    }
  }
}
</style>
